import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./scss/index.scss";
import Store from "./store";
import { Provider } from "react-redux";
import Home from "./components/Home";
import Login from "./components/Auth/Login";
import AuthRoutes from "./protectedRoutes/AuthRoutes";
import PrivateRoutes from "./protectedRoutes/PrivateRoutes";
import RootLayout from "./layouts/RootLayout";
import DashboardMain from "./pages/Dashboard/DashboardMain";
import CategoriesMain from "./pages/Categories/CategoriesMain";
import AddCategories from "./pages/Categories/AddCategories";
import EditCategories from "./pages/Categories/EditCategories";
import AddSubCategories from "./pages/SubCategories/AddSubCategories";
import SubCategoriesMain from "./pages/SubCategories/SubCategoriesMain";
import EditSubCategories from "./pages/SubCategories/EditSubCategories";
import ProductMain from "./pages/Products/ProductMain";
import AddProduct from "./pages/Products/AddProduct";
import ManageUserMain from "./pages/ManageUser/ManageUserMain";
import AddUser from "./pages/ManageUser/AddUser";
import SetNewPassword from "./components/Auth/SetNewPassword";

function App() {
  return (
    <Provider store={Store}>
      <Router>
        <Routes>
          <Route
            element={
              <RootLayout>
                <PrivateRoutes />
              </RootLayout>
            }
          >
            <Route path="/dashboard" exact element={<DashboardMain />} />
            <Route path="/categories" exact element={<CategoriesMain />} />
            <Route path="/add-categories" exact element={<AddCategories />} />
            <Route
              path="/sub-categories"
              exact
              element={<SubCategoriesMain />}
            />
            <Route
              path="/add-sub-categories"
              exact
              element={<AddSubCategories />}
            />
            <Route
              path="/edit-categories/:id"
              exact
              element={<EditCategories />}
            />
            <Route
              path="/edit-sub-categories/:id"
              exact
              element={<EditSubCategories />}
            />
            <Route path="/products" exact element={<ProductMain />} />
            <Route path="/add-product" exact element={<AddProduct />} />
            <Route
              path="/edit-product/:productId"
              exact
              element={<AddProduct />}
            />
            <Route path="/manage-users" exact element={<ManageUserMain />} />
            <Route path="/add-user" exact element={<AddUser />} />
            <Route path="/edit-user/:userId" exact element={<AddUser />} />
          </Route>

          <Route path="/" element={<AuthRoutes />}>
            <Route index element={<Login />} />
          </Route>

          <Route
            path="/users/:id/set-new-password/:token"
            element={<SetNewPassword />}
          />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
