import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RESET_SUCCESS } from "../../store/Types/AuthTypes";
import { toast } from "react-toastify";

export default function DashboardMain() {
  const { success } = useSelector((state) => state.AuthReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (success) {
      toast.success(success);
      dispatch({ type: RESET_SUCCESS });
    }
  }, [success]);
  return (
    <div>
      DashboardMain
      <div></div>
    </div>
  );
}
